import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { GoPrimitiveDot } from 'react-icons/go'



const ValuesOne = ({image}) => {
    const { rpdata } = useContext(GlobalDataContext)

    const WhyChooseUS = [
        {
            desc: "Expertise and Experience: With over 5 years of experience in the industry, our team has the knowledge and skills to handle all aspects of pool construction and remodeling. We have a proven track record of delivering high-quality results."
        },
        {
            desc: "Customized Solutions: We understand that every client has unique preferences and needs. That's why we offer personalized solutions tailored to your specific requirements. From design to execution, we work closely with you to bring your vision to life."
        },
        {
            desc: "Quality and Craftsmanship: We take pride in our commitment to excellence and attention to detail. Our team uses only the finest materials and employs skilled craftsmen to ensure the highest quality in every aspect of the project."
        },
        {
            desc: "Customer Satisfaction: Customer satisfaction is at the core of our business. We strive to provide exceptional service and exceed our clients' expectations. We prioritize clear communication, timely completion, and transparency throughout the entire process."
        },
        {
            desc: "Comprehensive Services: From pool construction and remodeling to cleaning and equipment installation, we offer a wide range of services to meet all your pool needs. Our team is equipped to handle projects of any scale and complexity."
        },

    ]
    return (
        <div
            className='py-[150px] bg-cover bg-no-repeat bg-fixed bg-center relative before:bg-zinc-900/50 before:absolute before:w-full before:h-full before:top-0'
            style={{ backgroundImage: `url("${image ? image : rpdata?.stock?.[0]}")` }}
        >
            <div className='relative w-4/5 mx-auto flex justify-end'>
                <div className='bg-white w-full lg:w-1/2 border-l-[15px] borderColor px-5 py-10 rounded-md'>
                    {
                        rpdata?.dbValues?.slice(0,2).map((items, index) => {
                            return (
                                <div key={index} className='pb-5'>
                                    <h4 className='flex items-center'>
                                        <GoPrimitiveDot className='dotsColor' fontSize={35} />
                                        <span>{items.title} </span>
                                    </h4>
                                    <p className='pl-9'>{items.description}</p>
                                </div>
                            )
                        })
                    }
                            <h4 className='flex items-center pb-2'>
                            <GoPrimitiveDot className='dotsColor' fontSize={35} />
                                <span>{ rpdata?.dbValues?.[2].title}</span>
                            </h4>

                    {
                        WhyChooseUS.map((item, index) => {
                            return(
                                
                                <div key={index} className='pb-5'>
                                    <li className='pl-9'>{item.desc}</li>
                            </div> 
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ValuesOne